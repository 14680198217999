<template>
  <div class="c-accordion">
    <div
      class="c-accordion__head"
      @click="toggle()"
      :style="{ borderLeftColor: color }"
      :class="{ 'c-accordion__head--open': isOpen }"
    >
      <div class="heading">
        <slot name="heading"></slot>
      </div>
      <img src="/img/close.svg" />
    </div>
    <div class="c-accordion__body" v-if="isOpen">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  components: {},
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#d2d2d2",
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  created() {
    this.isOpen = this.open;
  },
};
</script>

<style lang="scss">
@import "../../scss/base/variables.scss";

.c-accordion {
  &__head {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 20px;
    padding-left: 35px;
    padding-right: 15px;
    border-top: 1px solid rgba(163, 145, 121, 0.5);
    // border-bottom: 2px solid white;
    margin-bottom: 2px;

    border-left: 10px solid #d2d2d2;

    .heading {
      font-family: $universeBold;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: -0.13px;
      line-height: 16px;
      color: #232323;

      strong {
        font-weight: 700;
      }

      .count {
        // font-family: $universeRegular;
        text-transform: lowercase;
        opacity: 0.5;
      }
    }

    img {
      width: 10px;
      transform: rotate(45deg);
    }

    &--open {
      img {
        transform: rotate(0deg);
      }
    }

    @media (hover: hover) {
      transition: $t-hover;
      &:hover {
        transition: $t-hover;
        background-color: #f6f0e8;
      }
    }
  }

  &__body {
    // border-top: 2px solid white;
    // padding-bottom: 10px;
  }
}
</style>
