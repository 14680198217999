var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-accordion" }, [
    _c(
      "div",
      {
        staticClass: "c-accordion__head",
        class: { "c-accordion__head--open": _vm.isOpen },
        style: { borderLeftColor: _vm.color },
        on: {
          click: function ($event) {
            return _vm.toggle()
          },
        },
      },
      [
        _c("div", { staticClass: "heading" }, [_vm._t("heading")], 2),
        _vm._v(" "),
        _c("img", { attrs: { src: "/img/close.svg" } }),
      ]
    ),
    _vm._v(" "),
    _vm.isOpen
      ? _c("div", { staticClass: "c-accordion__body" }, [_vm._t("body")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }