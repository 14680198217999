<template>
	<div class="qd-cookie-popup" v-if="showPopup">
		<div class="qd-cookie-popup__inner">
			<strong>
				Vi bruger cookies til statistik
			</strong>

			<p>
				Ved at klikke "Accepter", godkender du vores brug af cookies til statistik.</p>
			<p>
				<a :href="cookiePageUrl">
					Læs mere om cookies
				</a>
			</p>

			<div class="qd-cookie-popup__action">
				<a href="#" class="o-btn o-btn--filled js-qd-cookie-popup-accept" v-on:click="acceptCookies">Jeg
					accepterer</a>
				<a href="#" class="o-btn o-btn--border js-qd-cookie-popup-deny" v-on:click="declineCookies">Nej tak</a>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'cookie-popup',
        props: {
            cookiePageUrl: String,
			keyName: {
				type: String,
				default: 'cookieConsent'
			},
			maxAge: 31536000,
            translations: {
                type: Object,
                required: true
            },
        },

		data() {
            return {
          		showPopup: false,
                pageviewAlreadySent: false
			}
		},

        methods: {
            acceptCookies: function (responder) {
                responder.preventDefault();
                this.showPopup = false
                this.setConsent(true, true)
            },
            declineCookies: function (responder) {
                responder.preventDefault();
                this.showPopup = false
                this.setConsent(false, true)
            },
            setConsent: function (consentState, persist) {
                var dataLayerVariable = {};

                if ( consentState === true && !this.pageviewAlreadySent ) {
                    dataLayerVariable.event = 'consentPageview';
                    this.pageviewAlreadySent = true;
                }

                // Set consent variable
                dataLayerVariable[ this.keyName ] = !!consentState;

                if (typeof(dataLayer) != "undefined"){
                    dataLayer.push(dataLayerVariable);
                }

                // Set the popup state
                if(consentState === null){
                    this.showPopup = true
                }


                if ( persist && consentState !== null ) {
                    this.set( this.keyName, consentState );
                } else if ( consentState === null ) {
                    this.remove( this.keyName );
                }
            },
            get: function (keyName) {
                if ( localStorage ) {
                    var value = localStorage.getItem( keyName );
                    return value !== null ? JSON.parse( value ) : null;
                } else {
                    var match = document.cookie.match(new RegExp('(^| )' + keyName + '=([^;]+)'));
                    return match ? JSON.parse( match[ 2 ] ) : null;
                }
            },
            set: function (keyName,value) {
                if ( localStorage ) {
                    localStorage.setItem( keyName, value );
                } else {
                    document.cookie = keyName + '=' + value + ';max-age=' + this.maxAge;
                }
            },
            remove: function (keyName) {
                if ( localStorage ) {
                    localStorage.removeItem( keyName );
                } else {
                    document.cookie = keyName + '=; Max-Age=-99999999;';
                }
            }
        },

		mounted() {
			this.setConsent(this.get(this.keyName),true)

            const links = document.querySelectorAll('[href="#reset-cookies"]');
            links.forEach((link) =>
                link.addEventListener("click", (e) => {
                    e.preventDefault();
                    this.setConsent(null);
                })
            );
        }
    }
</script>

<style lang="scss">
	.qd-cookie-popup * {
		box-sizing: border-box;
	}

	.qd-cookie-popup {
		position: fixed;
		z-index: 5;
		bottom: 0;
		width: 100%;
		padding-top: 2.5rem;
		padding-bottom: 4.5rem;
		color: white;
		background-color: #292929;
		font-size: 0.875rem;

		&.is-undecided {
			display: block;
		}

		&__inner {
			margin: 0 auto;
			width: 100%;
			max-width: 1220px;
			box-sizing: border-box;
			padding: 0 35px;
			font-size: 18px;
			letter-spacing: 0;
			line-height: 25px;

			strong {
				display: block;
				margin-bottom: 15px;
			}

			p {
				margin-bottom: 8px;
				line-height: 1.55;
				font-size: 14px;
			}

			a {
				color: #fff;
				text-decoration: underline;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&__action {
			margin-top: 25px;

			.o-btn {
				margin-right: 5px;
				text-align: center;
				text-decoration: none;
				padding-left: 2.15625rem;
				padding-right: 2.15625rem;
				transition: all ease .3s;

				&:after{
					display: none;
				}

				&--filled{
					&:hover{
						background: #fff;
						border-color: #fff;
					}
				}

				&--border{
					border-color: rgba(255,255,255,.2);
					background: transparent;
					&:hover{
						background: #fff;
						color: #F29100;
						border-color: #fff;
					}
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
</style>
