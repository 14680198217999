var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "responsive-browser js-responsive-browser",
        class: _vm.selectedView,
      },
      [
        _c("div", { staticClass: "responsive-browser__browser" }, [
          _c("div", {
            staticClass:
              "responsive-browser__screenshot responsive-browser__screenshot--placeholder",
          }),
          _vm._v(" "),
          _vm.desktop
            ? _c("div", { staticClass: "responsive-browser__desktop-bar" })
            : _vm._e(),
          _vm._v(" "),
          _vm.tablet
            ? _c("div", { staticClass: "responsive-browser__tablet-btn" })
            : _vm._e(),
          _vm._v(" "),
          _vm.mobile
            ? _c("div", { staticClass: "responsive-browser__mobile-btn" })
            : _vm._e(),
          _vm._v(" "),
          _vm.desktop
            ? _c("div", {
                staticClass: "responsive-browser__screenshot",
                class: { "is-visible": _vm.selectedImage === "desktop" },
                style: "background-image: url(" + _vm.desktop + ")",
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.tablet
            ? _c("div", {
                staticClass: "responsive-browser__screenshot",
                class: { "is-visible": _vm.selectedImage === "tablet" },
                style: "background-image: url(" + _vm.tablet + ")",
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.mobile
            ? _c("div", {
                staticClass: "responsive-browser__screenshot",
                class: { "is-visible": _vm.selectedImage === "mobile" },
                style: "background-image: url(" + _vm.mobile + ")",
              })
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("nav", { staticClass: "responsive-browser__nav" }, [
      _vm.desktop
        ? _c(
            "a",
            {
              staticClass: "js-change-browser",
              class: { "is-on": _vm.selectedView === "desktop" },
              on: {
                click: function ($event) {
                  return _vm.change("desktop")
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    version: "1.1",
                    x: "0",
                    y: "0",
                    width: "60",
                    height: "40",
                    viewBox: "0 0 60 40",
                    "xml:space": "preserve",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#B3B3B3",
                      d:
                        "M57.6 35H55V3c0-1.7-1.8-3-3.4-3h-44C5.9 0 5 1.3 5 3v32H1.6C0.5 35 0 35.9 0 37v1c0 1.1 0.5 2 1.6 2h56c1.1 0 2.4-0.9 2.4-2v-1C60 35.9 58.7 35 57.6 35zM7 5c0-1.7 0.9-3 2.6-3h40C51.2 2 53 3.3 53 5v30H7V5z",
                    },
                  }),
                ]
              ),
              _vm._v("\n\t\t\tDesktop\n\t\t"),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tablet
        ? _c(
            "a",
            {
              staticClass: "js-change-browser",
              class: { "is-on": _vm.selectedView === "tablet" },
              on: {
                click: function ($event) {
                  return _vm.change("tablet")
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    version: "1.1",
                    x: "0",
                    y: "0",
                    width: "46",
                    height: "35",
                    viewBox: "0 0 46 35",
                    "xml:space": "preserve",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#B3B3B3",
                      d:
                        "M3 0C1.4 0 0 1.4 0 3v29c0 1.7 1.4 3 3 3h40c1.7 0 3-1.3 3-3V3c0-1.6-1.3-3-3-3H3zM40 28.5c0 1.6-1.3 3-3 3H7c-1.7 0-3-1.4-3-3v-22c0-1.7 1.3-3 3-3h30c1.7 0 3 1.3 3 3V28.5zM44.5 17.5c0 0.8-0.7 1.5-1.5 1.5s-1.5-0.7-1.5-1.5S42.2 16 43 16 44.5 16.7 44.5 17.5z",
                    },
                  }),
                ]
              ),
              _vm._v("\n\t\t\tTablet\n\t\t"),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mobile
        ? _c(
            "a",
            {
              staticClass: "js-change-browser",
              class: { "is-on": _vm.selectedView === "mobile" },
              on: {
                click: function ($event) {
                  return _vm.change("mobile")
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    version: "1.1",
                    x: "0",
                    y: "0",
                    width: "17",
                    height: "33",
                    viewBox: "0 0 17 33",
                    "xml:space": "preserve",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#B3B3B3",
                      d:
                        "M3 0C1.4 0 0 1.4 0 3v27c0 1.6 1.4 3 3 3h11c1.6 0 3-1.4 3-3V3c0-1.6-1.4-3-3-3H3zM10 29c0 0.8-0.7 1.5-1.5 1.5S7 29.8 7 29s0.7-1.5 1.5-1.5S10 28.2 10 29zM15 23c0 1.6-1.4 3-3 3H5c-1.7 0-3-1.4-3-3V5c0-1.7 1.3-3 3-3h7c1.6 0 3 1.3 3 3V23z",
                    },
                  }),
                ]
              ),
              _vm._v("\n\t\t\tMobil\n\t\t"),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }