<template>
	<div>
		<div class="responsive-browser js-responsive-browser" :class="selectedView">
			<div class="responsive-browser__browser">
				<div class="responsive-browser__screenshot responsive-browser__screenshot--placeholder"></div>

				<div class="responsive-browser__desktop-bar" v-if="desktop"></div>
				<div class="responsive-browser__tablet-btn" v-if="tablet"></div>
				<div class="responsive-browser__mobile-btn" v-if="mobile"></div>

				<div class="responsive-browser__screenshot" :class="{ 'is-visible' : selectedImage === 'desktop'}" :style="'background-image: url('+desktop+')'" v-if="desktop"></div>

				<div class="responsive-browser__screenshot" :class="{ 'is-visible' : selectedImage === 'tablet'}" :style="'background-image: url('+tablet+')'" v-if="tablet"></div>

				<div class="responsive-browser__screenshot" :class="{ 'is-visible' : selectedImage === 'mobile'}" :style="'background-image: url('+mobile+')'" v-if="mobile"></div>
			</div>
		</div>

		<nav class="responsive-browser__nav">
			<a class="js-change-browser" :class="{ 'is-on' : selectedView === 'desktop'}" v-on:click="change('desktop')" v-if="desktop">
				<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="60" height="40"
					 viewBox="0 0 60 40" xml:space="preserve"><path fill="#B3B3B3" d="M57.6 35H55V3c0-1.7-1.8-3-3.4-3h-44C5.9 0 5 1.3 5 3v32H1.6C0.5 35 0 35.9 0 37v1c0 1.1 0.5 2 1.6 2h56c1.1 0 2.4-0.9 2.4-2v-1C60 35.9 58.7 35 57.6 35zM7 5c0-1.7 0.9-3 2.6-3h40C51.2 2 53 3.3 53 5v30H7V5z"/></svg>
				Desktop
			</a>

			<a class="js-change-browser" :class="{ 'is-on' : selectedView === 'tablet'}" v-on:click="change('tablet')" v-if="tablet">
				<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="46" height="35"
					 viewBox="0 0 46 35" xml:space="preserve"><path fill="#B3B3B3" d="M3 0C1.4 0 0 1.4 0 3v29c0 1.7 1.4 3 3 3h40c1.7 0 3-1.3 3-3V3c0-1.6-1.3-3-3-3H3zM40 28.5c0 1.6-1.3 3-3 3H7c-1.7 0-3-1.4-3-3v-22c0-1.7 1.3-3 3-3h30c1.7 0 3 1.3 3 3V28.5zM44.5 17.5c0 0.8-0.7 1.5-1.5 1.5s-1.5-0.7-1.5-1.5S42.2 16 43 16 44.5 16.7 44.5 17.5z"/></svg>
				Tablet
			</a>

			<a class="js-change-browser" :class="{ 'is-on' : selectedView === 'mobile'}" v-on:click="change('mobile')" v-if="mobile">
				<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="17" height="33"
					 viewBox="0 0 17 33" xml:space="preserve"><path fill="#B3B3B3" d="M3 0C1.4 0 0 1.4 0 3v27c0 1.6 1.4 3 3 3h11c1.6 0 3-1.4 3-3V3c0-1.6-1.4-3-3-3H3zM10 29c0 0.8-0.7 1.5-1.5 1.5S7 29.8 7 29s0.7-1.5 1.5-1.5S10 28.2 10 29zM15 23c0 1.6-1.4 3-3 3H5c-1.7 0-3-1.4-3-3V5c0-1.7 1.3-3 3-3h7c1.6 0 3 1.3 3 3V23z"/></svg>
				Mobil
			</a>
		</nav>
	</div>
</template>

<script>
    export default {
        name: 'responsive-browser',
        props: {
            desktop: String,
			tablet: String,
			mobile: String,
			activeView: {
                type: String,
				default: 'desktop'
			}
		},

		data() {
            return {
				selectedView: this.activeView,
                selectedImage: this.activeView,
			}
        },

        methods: {
			change: function (view) {
				this.selectedImage = '';

                setTimeout(function () {
                    this.selectedView = view
                }.bind(this), 400)

                setTimeout(function () {
                    this.selectedImage = view
                }.bind(this), 800)
            }
		}
    }
</script>

<style lang="scss">
	$t-hover: 0.2s ease;
	$c-brand: #004141;

	.responsive-browser {
		padding-bottom: ( 800 / 1280 ) * 100%;
		position: relative;

		&__browser {
			background-color: #ececec;
			border-radius: 5px;
			bottom: 0;
			left: 50%;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
			transition: all 400ms ease-out;
		}

		&__screenshot {
			background-color: #fff;
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
			border-radius: 3px;
			border: 1px solid rgba(0, 0, 0, .2);
			left: 4px;
			opacity: 0;
			position: absolute;
			right: 4px;
			transition: all 400ms ease-out;

			&.is-visible {
				opacity: 1;
			}
		}

		&__screenshot--placeholder {
			&,
			&.is-visible {
				opacity: 1;
			}
		}

		&__desktop-bar {
			background-color: #fff;
			border-radius: 2px;
			height: 4%;
			left: 50%;
			opacity: 0;
			position: absolute;
			top: 2.1%;
			transform: translateX(-50%);
			transition: all 400ms ease-out;
			width: 70%;
		}

		&__tablet-btn {
			background-color: #fff;
			border-radius: 50%;
			opacity: 0;
			position: absolute;
			right: 2.5%;
			top: 50%;
			transform: translateY(-50%);
			transition: all 400ms ease-out;
			width: 3.5%;
			z-index: 10;

			&:after {
				content: ' ';
				display: block;
				padding-bottom: 100%;
			}
		}

		&__mobile-btn {
			background-color: #fff;
			border-radius: 50%;
			bottom: 1.5%;
			left: 50%;
			opacity: 0;
			position: absolute;
			transform: translateX(-50%);
			transition: all 400ms ease-out;
			width: 12%;
			z-index: 10;

			&:after {
				content: ' ';
				display: block;
				padding-bottom: 100%;
			}
		}

		&__nav {
			margin-top: 40px;
			text-align: center;

			svg {
				display: block;
				margin: 0 auto;

				path {
					transition: fill $t-hover;
				}
			}

			a {
				color: #b3b3b3;
				cursor: pointer;
				display: inline-block;
				font-size: 14px;
				font-weight: bold;
				text-align: center;
				transition: color $t-hover;

				& + a {
					margin-left: 24px;
				}

				&:hover {
					color: #777;

					svg path {
						fill: #777;
					}
				}

				&.is-on {
					color: $c-brand;

					svg path {
						fill: $c-brand;
					}
				}

			}

		}

	}

	.responsive-browser.desktop {

		.responsive-browser__browser {
			width: 100%;
		}

		.responsive-browser__screenshot {
			bottom: 4%;
			top: 8%;
		}

		.responsive-browser__desktop-bar {
			opacity: 1;
		}
	}

	.responsive-browser.tablet {

		.responsive-browser__browser {
			border-radius: 15px;
			width: 95%;
		}

		.responsive-browser__screenshot {
			bottom: 2%;
			left: 8%;
			right: 8%;
			top: 2%;
		}

		.responsive-browser__tablet-btn {
			opacity: 1;
		}
	}

	.responsive-browser.mobile {

		.responsive-browser__browser {
			border-radius: 30px;
			width: 31%;
		}

		.responsive-browser__screenshot {
			bottom: 9%;
			top: 7%;
		}

		.responsive-browser__mobile-btn {
			opacity: 1;
		}
	}

</style>
